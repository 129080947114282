<template>
  <FocusTrap>
    <div id="mycard" class="card">

      <div class="card-header header-elements-inline" style="background-color: whitesmoke; padding: 10px;">
        <h5 v-if="receipt.id == 0" class="card-title">Receipt Creation</h5>
        <h5 v-if="receipt.id > 0" class="card-title"> Receipt Updation</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a class="list-icons-item" data-action="reload" ></a>
            <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
          </div>
        </div>
      </div>


      <div class="card-body">
        <p/>
        <div class="form-group row">
          <label class="col-md-2 col-form-label">Date:</label>
          <div class="col-md-10 col-sm-10" >
            <input  ref="elDate" type="date" class="form-control" placeholder="Enter Doc Date here..." v-if="receipt" v-model="receipt.doc_date" autofocus >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Category:</label>
          <div class="col-md-10 col-sm-10">
            <select ref="el_type" class="form-control" required="" v-model="receipt.category_id" >
              <option value="1" selected>CONSULTING FEE</option>
              <option value="2" selected>LABOUR</option>
              <option value="3" selected>MATERIAL</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Project:</label>
          <div class="col-md-10 col-sm-10">
            <select ref="elProject" class="form-control" required="" v-model="receipt.project_id" >
              <option value="0" selected>All</option>
              <option v-for="pro in projects" :value="pro.id">
                {{ pro.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-sm-10 col-form-label">Amount:</label>
          <div class="col-md-10">
            <input ref="elAmount" id="txtAmount"  type="number" class="form-control" placeholder="Enter Voucher Amount here..." min="0" v-if="receipt" v-model="receipt.amount" step="any">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Remarks:</label>
          <div class="col-md-10 col-sm-10">
            <input ref="el_remarks" type="text" class="form-control" placeholder="Voucher Remarks" v-if="receipt" v-model="receipt.remarks" autocomplete="off" maxlength="100" >
          </div>
        </div>

      </div>

      <div class="card-footer">
        <div class="text-right" >
          <button ref="el_save" type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
        </div>
      </div>

    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
import moment from 'moment-timezone'
import { store } from '@/store/store.js'
export default {
  name: 'ReceiptForm',
  store,
  data () {
    return {
      receipt: JSON.parse('{"id":0,"finyear":2000,"doc_date":"2000-01-01","doc_no":"","amount":0,"remarks":"","project_id":0,"ledger_id":1}'),
      projects : [],
    }
  },
  props: {
    myproject: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"finyear":2000,"doc_date":"2000-01-01","doc_no":"","amount":0,"remarks":"","project_id":0,"ledger_id":1}'),
    }
  },

  beforeMount () {
    this.receipt = this.myproject; // save props data to itself's data and deal with tms
  },

  component: {

  },
  created () {
    this.$data.receipt = JSON.parse('{"id":0,"finyear":2000,"doc_date":"2000-01-01","doc_no":"","amount":0,"remarks":"","project_id":0,"ledger_id":1}')
  },
  mounted () {
    const self = this;
    this.$data.receipt.doc_date = moment().format('YYYY-MM-DD');
    self.loadProjects();
    self.$refs.elDate.focus();
  },
  methods: {
    closeWindow(){
      this.$emit('receipt_window_closed');
      this.$modal.hide('receipt_window');
      this.receipt = JSON.parse('{"id":0,"finyear":2000,"doc_date":"2000-01-01","doc_no":"","amount":0,"remarks":"","project_id":0,"ledger_id":1}');
    },
    clear(){
      this.$data.receipt = JSON.parse('{"id":0,"finyear":2000,"doc_date":"2000-01-01","doc_no":"","amount":0,"remarks":"","project_id":0,"ledger_id":1}')
    },
    loadProjects(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        credentials: 'same-origin',
        headers: userService.authHeader()
      };

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.projects = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/bms/projects`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray (resp.data)) {
            self.$data.projects = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });

    },
    saveDocument(){
      const self = this;

      let myvoucher =  JSON.parse(JSON.stringify(self.$data.receipt));

      myvoucher.finyear = self.$store.state.user.finyear;
      myvoucher.cmp_id = self.$store.state.user.company.id;
      myvoucher.doc_date = moment(myvoucher.doc_date).format('YYYY-MM-DD');
      myvoucher.category_id = parseInt(self.$data.receipt.category_id);
      myvoucher.project_id = parseInt(self.$data.receipt.project_id);
      myvoucher.amount = parseFloat(self.$data.receipt.amount);

      let valid_data = true;
      if(myvoucher.project_id < 1) {
        self.$refs.elProject.focus();
        alert("Invalid Project Selected");
        valid_data = false;
      }else if(myvoucher.category_id < 1) {
        alert("Invalid Category Selected");
        valid_data = false;
      }else if(myvoucher.amount <= 0) {
        self.$refs.elAmount.focus();
        $('#txtAmount').focus();
        alert("Invalid Amount");
        valid_data = false;
      }

      const requestOptions = {
        method:  (self.$data.receipt.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(myvoucher)
      };

      if(valid_data) {

        try {
          $('#mycard').block({
            message: '<i class="icon-spinner2 spinner"></i>',
            overlayCSS: {
              backgroundColor: '#fff',
              opacity: 0.8,
              cursor: 'wait',
              'box-shadow': '0 0 0 1px #ddd'
            },
            css: {
              border: 0,
              padding: 0,
              backgroundColor: 'none'
            }
          });

          fetch(`${process.env.VUE_APP_ROOT_API}api/bms/receipt`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok) {
              self.$emit('receipt_saved', resp.data);
              swal({ title: "Success!", text: resp.msg, type: "success" });
              self.receipt = JSON.parse('{"id":0,"finyear":2000,"doc_date":"2000-01-01","doc_no":"","amount":0,"remarks":"","project_id":0,"ledger_id":1}');
            } else {
              swal({ title: "Oops!", text: resp.msg, type: "error" });
            }
          }).catch(function (err) {
            swal({ title: "Oh noes!", text: err.toString(), type: "error" });
          }).finally(function () {
            $('#mycard').unblock();
          });
        } catch (e) {
          alert(e);
        }
      }

    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
